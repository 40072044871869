import React, { useRef } from 'react';
import { Auth } from '../../api';
import { Form } from '../Global';

const Default = (props) => {
    const {
        setAccount,
        setCurrentPage,
        Views
    } = props;

    const email = useRef();
    const password = useRef();

    const logIn = async (e, errors, setErrors) => {
        e.preventDefault();

        setErrors([]);

        const resp = await Auth.log_in({
            email: email.current.value,
            password: password.current.value,
        });

        if (resp.errors) {
            setErrors([...resp.errors]);
            return;
        }

        localStorage.setItem('math_mind_user_id', resp.id);
        setAccount(resp);
    };

    return (
        <>
            <Form
                handleSubmit={logIn}
                inputs={[
                    {
                        placeholder: "Email..",
                        ref: email,
                        required: true
                    },
                    {
                        placeholder: "Password..",
                        ref: password,
                        type: 'password',
                        required: true
                    }
                ]}
                title="Log in"
            />
            <a href="#" onClick={() => setCurrentPage(Views.signIn)}>Back to sign in</a>
        </>
    );
};

export default Default;
