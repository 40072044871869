class Object {
    constructor(x, y, width, height, name) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.incomingX = 0;
        this.incomingY = 0;
        this.battle = false;
        this.chooseSpell = false;
        this.math = false;
        this.spell = null;
        this.health = width;
        this.name = name;
    }
}

export default Object