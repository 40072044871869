import React, { useState, useEffect } from 'react';
import { Auth } from './api';
import AuthPage from './components/Auth';
import Home from './components/Home';
import PlayPage from './components/Play';
import './App.css';

function App() {
  const [account, setAccount] = useState();

  useEffect(() => {
    const getUser = async () => {
      if (localStorage.getItem("user_id")) {
        const resp = await Auth.get_user({ id: localStorage.getItem("user_id") })
        return setAccount(resp[0]);
      }
    };

    getUser();
  }, []);

  console.log(account);

  if (!account) {
    return (
      <>
        <Home />
        <AuthPage
          setAccount={setAccount}
        />
      </>
    );
  }

  return (
    <>
      <PlayPage
        account={account}
      />
    </>
  );
}

export default App;
