class Grade2 {
    constructor() {
        this.problems = [
            {
                type: 'addition',
                problem: 'Solve -1- + -2-:'
            },
            {
                type: 'subtraction',
                problem: 'Solve -1- - -2-:'
            },
            {
                type: 'multipication',
                problem: 'Solve -1- x -2-:'
            },
            {
                type: 'division',
                problem: 'Solve -1- ÷ -2-:'
            }
        ];
    }

    findRandomProblem = () => {
        const problem = this.problems[Math.floor(Math.random() * this.problems.length)];
        const newProblem = { ...problem };

        if (newProblem.type === 'addition' || newProblem.type === 'subtraction') {
            const num1 = Math.floor(Math.random() * 100);
            const num2 = Math.floor(Math.random() * 100);
            newProblem.problem = newProblem.problem.replace('-1-', num1);
            newProblem.problem = newProblem.problem.replace('-2-', num2);
            newProblem.answer = newProblem.type === 'addition' ? num1 + num2 : num1 - num2;
        }

        if (newProblem.type === 'multipication') {
            const num1 = Math.floor(Math.random() * 4);
            const num2 = Math.floor(Math.random() * 4);
            newProblem.problem = newProblem.problem = problem.problem.replace('-1-', num1);
            newProblem.problem = newProblem.problem.replace('-2-', num2);
            newProblem.answer = num1 * num2;
        }

        if (newProblem.type === 'division') {
            const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            const divisor = Math.floor(Math.random() * 10) + 1;
            let dividend;
    
            while (!dividend) {
                const randomNum = nums[Math.floor(Math.random() * nums.length)];
                const divided = divisor / randomNum;
                if (`${Math.floor(divided)}` === `${divided}`) {
                    dividend = randomNum;
                }
            }

            newProblem.problem = newProblem.problem.replace('-1-', divisor);
            newProblem.problem = newProblem.problem.replace('-2-', dividend);
            newProblem.answer = divisor / dividend;
        }

        return newProblem;
    }
}

export default new Grade2();
