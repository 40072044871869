import React from 'react';

const Default = () => {
    return (
        <>
            <h1>Genie</h1>
        </>
    );
};

export default Default;
