import React, { useRef } from 'react';
import { Auth } from '../../api';
import { Form } from '../Global';

const Default = (props) => {
    const {
        setAccount,
        setCurrentPage,
        Views
    } = props;

    const name = useRef();
    const email = useRef();
    const password = useRef();
    const passwordConf = useRef();
    const grade = useRef();
    const hairColor = useRef();
    const hairStyle = useRef();
    const eyeColor = useRef();
    const skinColor = useRef();
    const gender = useRef();

    const signIn = async (e, errors, setErrors) => {
        e.preventDefault();

        setErrors([]);

        if (password.current.value !== passwordConf.current.value) {
            setErrors([
                ...errors,
                'Password and password confirmation must be the same.'
            ]);

            return;
        }

        const resp = await Auth.sign_in({
            name: name.current.value,
            email: email.current.value,
            password: password.current.value,
            grade: grade.current.value,
            hair_style: hairStyle.current.options[hairStyle.current.selectedIndex].value,
            hair_color: hairColor.current.options[hairColor.current.selectedIndex].value,
            eye_color: eyeColor.current.options[eyeColor.current.selectedIndex].value,
            skin_color: skinColor.current.options[skinColor.current.selectedIndex].value,
            gender: gender.current.options[gender.current.selectedIndex].value
        });

        if (resp.errors) {
            setErrors([...resp.errors]);
            return;
        }

        localStorage.setItem('user_id', resp.id);
        setAccount(resp);
    };

    return (
        <>
            <Form
                handleSubmit={signIn}
                inputs={[
                    {
                        placeholder: "Name..",
                        ref: name,
                        required: true
                    },
                    {
                        placeholder: "Email..",
                        ref: email,
                        required: true
                    },
                    {
                        placeholder: "Password..",
                        ref: password,
                        type: 'password',
                        required: true
                    },
                    {
                        placeholder: "Password Confirmation..",
                        ref: passwordConf,
                        type: 'password',
                        required: true
                    },
                    {
                        placeholder: "Grade..",
                        ref: grade,
                        type: 'number',
                        style: { width: '70px' },
                        required: true,
                        max: 5,
                        min: 1
                    },
                    {
                        html: (
                            <>
                                <div>Hair Style</div>
                                <select ref={hairStyle}>
                                    <option>Suave</option>
                                    <option>Casual</option>
                                    <option>Macho</option>
                                    <option>Peak of Fashion</option>
                                </select>
                                <br />
                                <br />
                            </>
                        ),
                    },
                    {
                        html: (
                            <>
                                <div>Hair Color</div>
                                <select ref={hairColor}>
                                    <option>Black</option>
                                    <option>Brown</option>
                                    <option>Blonde</option>
                                    <option>Red</option>
                                </select>
                                <br />
                                <br />
                            </>
                        ),
                    },
                    {
                        html: (
                            <>
                                <div>Eye Color</div>
                                <select ref={eyeColor}>
                                    <option>Green</option>
                                    <option>Brown</option>
                                    <option>Blue</option>
                                </select>
                                <br />
                                <br />
                            </>
                        ),
                    },
                    {
                        html: (
                            <>
                                <div>Skin Color</div>
                                <select ref={skinColor}>
                                    <option>Black</option>
                                    <option>Brown</option>
                                    <option>White</option>
                                </select>
                                <br />
                                <br />
                            </>
                        ),
                    },
                    {
                        html: (
                            <>
                                <div>Gender</div>
                                <select ref={gender}>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                                <br />
                                <br />
                            </>
                        ),
                    }
                ]}
                title="Sign in"
            />
            <a href="#" onClick={() => setCurrentPage(Views.logIn)}>Already have an Account?</a>
        </>
    );
};

export default Default;
