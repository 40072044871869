import styled from "styled-components";
import React from "react";
import Object from "./Object";
import { useState } from "react";
import { useInterval } from '../Global';

const Block = styled.div`
    background-color: red;
    position: absolute;
    ${(props) => (
        `
            width: ${props.data?.width}px;
            height: ${props.data?.height}px;
            top: ${props.data?.y}px;
            left: ${props.data?.x}px;
        `
    )}
`;

function Player (x, y, name) {
    const [data, setData] = useState(new Object(x, y, 100, 100, name));
    this.data = data;
    this.setData = setData;

    this.move = (e) => {
        if (!data.battle) {
            const mouse = {
                x: e.clientX - (data.width / 2),
                y: e.clientY - (data.height / 2)
            };

            data.incomingX = mouse.x;
            data.incomingY = mouse.y;
        }
    };

    this.clip = (num, min, max) => {
        if (num > max) {
            return max
        }

        if (num < min) {
            return min;
        }

        return num;
    }

    useInterval(() => {
        let newData = { ...data };

        if (data.incomingX !== data.x && data.incomingX !== 0) {
            newData = { ...newData, x: data.x + this.clip(data.incomingX - data.x, -5, 5) };
        }

        if (data.incomingY !== data.y && data.incomingY !== 0) {
            newData = { ...newData, y: data.y + this.clip(data.incomingY - data.y, -5, 5) };
        }
        if ((data.incomingX !== data.x || data.incomingY !== data.y) && (data.incomingY !== 0 || data.incomingX !== 0)) {
            this.setData(newData);
        }
    }, 20);

    this.show = () => {
        return (
            <Block data={data} />
        );
    };
}

export default Player