import React, { useState } from 'react';

const Default = (props) => {
    const {
        handleSubmit,
        inputs,
        title
    } = props;

    const defaultErrors = [];
    const [errors, setErrors] = useState([...defaultErrors]);

    return (
        <form onSubmit={(e) => handleSubmit(e, errors, setErrors)}>
            <h1>{title}</h1>
            {inputs.map((input, inputIdx) => {
                if (input.html) return input.html;
                return (
                    <div key={`input-${inputIdx}`}>
                        <input
                            {...input}
                        />
                        <br />
                        <br />
                    </div>
                );
            })}
            <input type="submit" value={title} />

            {errors.map((error, errorIdx) => (
                <h3 key={`error${errorIdx}`}>{error}</h3>
            ))}
        </form>
    );
};

export default Default;
