import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Pet from './Pet';
import Player from './Player';
import { Grade2 } from '../Math';

const World = styled.div`
    width: 100vw;
    height: 100vh;
`;

const Battle = styled.button`
    position: absolute;
    top: 90vh;
    max-width: 100px;
    left: calc(50vw - 50px);
`;

const Shade = styled(World)`
    background-color: black;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    background-color: white;
    width: 200px;
    color: black;
    padding: 10px;
    border-radius: 5%;
`;

const Default = (props) => {
    const {
        account
    } = props;

    const UserPlayer = new Player(200, 200, account.name);

    const [wrongAnswer, setWrongAnswer] = useState(false);
    const [currentPet, setCurrentPet] = useState(UserPlayer);

    const pets = []; // account.pets.map((pet) => new Pet(100, 100, currentPet, setCurrentPet, pet.name));
    const Enemy = new Pet(500, 300, currentPet, setCurrentPet, 'Forcha');

    const answer = useRef();

    const chooseSpell = (name) => {
        currentPet.setData({
            ...currentPet.data,
            chooseSpell: false,
            spell: name,
            math: Grade2.findRandomProblem()
        });
    };

    const checkAnswer = () => {
        if (Number(answer.current.value) === currentPet.data.math.answer) {
            currentPet.setData({
                ...currentPet.data,
                math: null
            });

            let newHealth = Enemy.data.health - 20;

            if (account.membership || account.admin) {
                newHealth = Enemy.data.health - 25;
            }

            Enemy.setData({
                ...Enemy.data,
                health: newHealth
            });
        } else {
            setWrongAnswer(true);
        }
    };

    return (
        <World onClick={(e) => UserPlayer.move(e)}>
            <UserPlayer.show />
            <Enemy.show />

            {pets.map((UserPlayerPet, UserPlayerPetIdx) => (
                <UserPlayerPet.show key={`UserPlayer-pet-${UserPlayerPetIdx}`} />
            ))}

            {currentPet.data.spell && !currentPet.data.chooseSpell && !currentPet.data.math && (
                <>
                    <h1>{currentPet.name} is using {currentPet.data.spell}</h1>
                </>
            )}

            {currentPet.data.battle && !currentPet.data.chooseSpell && !currentPet.data.math && (
                <>
                    <Battle onClick={() => currentPet.setData({ ...currentPet.data, chooseSpell: true, spell: false })}>Battle</Battle>
                </>
            )}

            {currentPet.data.chooseSpell && (
                <Shade>
                    <Modal>
                        <h1>Pick Spell</h1>
                        <button onClick={() => chooseSpell('Siracha')}>Siracha</button>
                        <button onClick={() => chooseSpell('Tornado')}>Tornado</button>
                        <button onClick={() => chooseSpell('Ball of Fire')}>Ball of Fire</button>
                    </Modal>
                </Shade>
            )}

            {currentPet.data.math && (
                <Shade>
                    <Modal>
                        <h1>Do Math!</h1>
                        <h3>{currentPet.data.math.problem}</h3>
                        <input
                            placeholder='Answer..'
                            ref={answer}
                        />
                        <br /><br />
                        <button onClick={checkAnswer}>Submit</button>
                        <br /><br />
                        {wrongAnswer && (
                            <h1>Sorry, Thats the wrong answer</h1>
                        )}
                    </Modal>
                </Shade>
            )}
        </World>
    );
};

export default Default;
