import styled from "styled-components";
import React from "react";
import Object from "./Object";
import { useState } from "react";
import { useInterval } from '../Global';

const Block = styled.div`
    position: absolute;
    ${(props) => (
        `
            background-color: ${props.data?.color};
            width: ${props.data?.width}px;
            height: ${props.data?.height}px;
            top: ${props.data?.y}px;
            left: ${props.data?.x}px;
        `
    )}
`;

function Pet(x, y, player, setCurrentPet, name) {
    const [data, setData] = useState(new Object(x, y, 100, 100, name));
    this.data = data;
    this.setData = setData;

    this.triggerFight = () => {
        setCurrentPet({
            ...player.data,
            x: 50,
            y: window.innerHeight / 2,
            incomingX: 0,
            incomingY: 0,
            battle: true
        });
        this.setData({
            ...this.data,
            x: window.innerWidth - (player.data.width + 50),
            y: window.innerHeight / 2,
            battle: true
        });
    };

    this.show = () => {
        return (
            <>
                <Block data={{ ...this.data, height: 15, color: 'red', y: this.data.y - 20 }} />
                <Block data={{ ...this.data, width: this.data.health, height: 15, color: 'green', y: this.data.y - 20 }} />
                <Block data={{ ...this.data, color: 'blue' }} onClick={() => this.triggerFight()} />
            </>
        );
    };
}

export default Pet;
