class Grade1 {
    constructor() {
        this.problems = [
            {
                problem: 'Solve -1- + -2-:'
            },
            {
                problem: 'Solve -1- - -2-:'
            }
        ];
    }

    findRandomProblem = () => {
        const problem = this.problems[Math.floor(Math.random() * this.problems.length)];
        problem.problem = problem.problem.replace('-1-', Math.floor(Math.random() * 10))
        problem.problem = problem.problem.replace('-2-', Math.floor(Math.random() * 10))

        return problem;
    }
}

export default new Grade1();

