async function call (url, method, dataToPass = {}) {
    try {
        let data = { method: method, headers: new Headers({'Content-Type':'application/json; charset=UTF-8'}) };

        if (method !== 'GET') {
            data.body = JSON.stringify(dataToPass);
        } else {
            url += await toUrlString(dataToPass);
        }

        let res = await fetch(url, data).then((data) => data.json());
        return await res;
    } catch (error) {
        console.error(error);
    }
}

async function toUrlString(data) {
    let urlString = '?';
    const values = Object.values(data);

    Object.keys(data).forEach((key, keyIdx) => {
        urlString += `${key}=${values[keyIdx]}&`
    });

    return urlString.slice(0, -1); // get rid of the last charactor
}

class Api {
    constructor() {
        this.base = 'https://hpho5z-5100.preview.csb.app';
        this.call = call;
        this.toUrlString = toUrlString;
    }
}


class AuthAPi extends Api {
    constructor () {
        super();
        this.endpoint = `${this.base}/users/`;
    }

    get_user(data) {
        return this.call(`${this.endpoint}`, 'GET', data);
    }

    sign_in(data) {
        return this.call(`${this.endpoint}new`, 'POST', data);
    }

    log_in(data) {
        // return this.call(`${this.endpoint}`, 'GET', data);
    }
}

// class PetApi extends Api {
//     constructor() {
//         super();
//         this.endpoint = `${this.base}/pet/`;
//     }

//     make_pet(data) {
//         return this.call(`${this.endpoint}`, 'POST', data);
//     }
// }

const Auth = new AuthAPi();
// const Pet = new PetApi();

export {
    Auth,
    // Pet
};

export default new Api();
