import React, { useState } from 'react';
import SignIn from './SignIn';
import LogIn from './LogIn';

const Default = (props) => {
    
    const Views = {
        signIn: 'signIn',
        logIn: 'logIn'
    };
    
    const [currentPage, setCurrentPage] = useState(Views.signIn);

    const signIn = (
        <SignIn
            {...props}
            setCurrentPage={setCurrentPage}
            Views={Views}
        />
    );

    const logIn = (
        <LogIn
            {...props}
            setCurrentPage={setCurrentPage}
            Views={Views}
        />
    );


    switch (currentPage) {
    case Views.signIn:
        return signIn;
    case Views.logIn:
        return logIn;
    default:
        return <div />;
    };
};

export default Default;
